/* eslint-disable no-restricted-globals */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */

(window ).__rewriteFramesAssetPrefixPath__ = '';

import { GLOBAL_OBJ } from '@sentry/utils';

/* eslint-disable no-constant-condition */

const globalObj = GLOBAL_OBJ ;

globalObj.SENTRY_RELEASE = { id: '27e9b411bd08760b354140aa58560ec9e29f3d47' };

// Enable module federation support (see https://github.com/getsentry/sentry-webpack-plugin/pull/307)
if ('capex-v3-frontend') {
  const key = 'kw-group' ? 'capex-v3-frontend@kw-group' : 'capex-v3-frontend';
  globalObj.SENTRY_RELEASES = globalObj.SENTRY_RELEASES || {};
  globalObj.SENTRY_RELEASES[key] = { id: '27e9b411bd08760b354140aa58560ec9e29f3d47' };
}

import * as Sentry from "@sentry/nextjs";

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
  dsn: SENTRY_DSN || "https://05a8500310db47498184f2e02ee2c4ad@o172030.ingest.sentry.io/4504293403852800",
  tracesSampleRate: 0.2,
  sanitizeKeys: [/password/i, /token/i],
  ignoreErrors: [
    "interrupted by a call to pause",
    "The request is not allowed by the user agent or the platform in the current context",
    "request was interrupted because the media was removed from the document",
    "background media was paused to save power",
    "AbortError: The operation was aborted.",
    "attempted to hard navigate to the same URL",
    "insight.min.js",
  ],
  beforeSend(event) {
    // Ignore Headless crawlers
    if (
      typeof window !== "undefined" &&
      window?.navigator &&
      window?.navigator?.userAgent.indexOf("HeadlessChrome") !== -1
    )
      return null;
    return event;
  },
});
